@import 'abstracts/variables';
@import 'abstracts/helpers';

.loader {
  .loader-design();

  :global {
    .rs-loader-spin,
    .rs-loader-spin::before,
    .rs-loader-spin::after {
      width: 58px;
      height: 58px;
    }
  }
}

.modal-header {
  padding-bottom: @spacer * 2;
}
